import * as THREE from 'three';
import { BufferGeometry, Mesh, OrthographicCamera, Scene, Vector3 } from 'three';

export class WireframeTrianglesScene {

  // Scene
  private sceneAspectRatio: number;
  private camera: OrthographicCamera;
  private scene: Scene;
  private renderer: any;

  // Geometry
  private numberOfTriangles: number = 1000;
  private trianglesDensity: number = 1100;
  private halfTrianglesDensity: number = this.trianglesDensity / 2;
  private triangleSize: number = 80;
  private halfTriangleSize: number = this.triangleSize / 2;
  private trianglesMesh: Mesh;

  private generateTrianglesGeometry(): void {
    const positions = new Float32Array( this.numberOfTriangles * 3 * 3 );
    const normals = new Float32Array( this.numberOfTriangles * 3 * 3 );
    const colors = new Float32Array( this.numberOfTriangles * 3 * 3 );
    const color = new THREE.Color();
    const pointA: Vector3 = new THREE.Vector3();
    const pointB: Vector3 = new THREE.Vector3();
    const pointC: Vector3 = new THREE.Vector3();
    const cb: Vector3 = new THREE.Vector3();
    const ab: Vector3 = new THREE.Vector3();
    const trianglesGeometry: BufferGeometry = new THREE.BufferGeometry();

    // Calculate the triangles properties
    for ( let index = 0, length = positions.length; index < length; index += 9 ) {
      /**
       * Positions
       */
      const x = Math.random() * this.trianglesDensity - this.halfTrianglesDensity;
      const y = Math.random() * this.trianglesDensity - this.halfTrianglesDensity;
      const z = Math.random() * this.trianglesDensity - this.halfTrianglesDensity;

      const ax = x + Math.random() * this.triangleSize - this.halfTriangleSize;
      const ay = y + Math.random() * this.triangleSize - this.halfTriangleSize;
      const az = z + Math.random() * this.triangleSize - this.halfTriangleSize;

      const bx = x + Math.random() * this.triangleSize - this.halfTriangleSize;
      const by = y + Math.random() * this.triangleSize - this.halfTriangleSize;
      const bz = z + Math.random() * this.triangleSize - this.halfTriangleSize;

      const cx = x + Math.random() * this.triangleSize - this.halfTriangleSize;
      const cy = y + Math.random() * this.triangleSize - this.halfTriangleSize;
      const cz = z + Math.random() * this.triangleSize - this.halfTriangleSize;

      positions[ index ]     = ax;
      positions[ index + 1 ] = ay;
      positions[ index + 2 ] = az;

      positions[ index + 3 ] = bx;
      positions[ index + 4 ] = by;
      positions[ index + 5 ] = bz;

      positions[ index + 6 ] = cx;
      positions[ index + 7 ] = cy;
      positions[ index + 8 ] = cz;

      /**
       * Flat face normals
       */
      pointA.set( ax, ay, az );
      pointB.set( bx, by, bz );
      pointC.set( cx, cy, cz );

      cb.subVectors( pointC, pointB );
      ab.subVectors( pointA, pointB );
      cb.cross( ab );

      cb.normalize();

      let nx = cb.x;
      let ny = cb.y;
      let nz = cb.z;

      normals[ index ]     = nx;
      normals[ index + 1 ] = ny;
      normals[ index + 2 ] = nz;

      normals[ index + 3 ] = nx;
      normals[ index + 4 ] = ny;
      normals[ index + 5 ] = nz;

      normals[ index + 6 ] = nx;
      normals[ index + 7 ] = ny;
      normals[ index + 8 ] = nz;

      /**
       * Colors
       */
      const vx = ( x / this.trianglesDensity ) + 0.5;
      const vy = ( y / this.trianglesDensity ) + 0.5;
      const vz = ( z / this.trianglesDensity ) + 0.5;

      color.setRGB( vx, vy, vz );

      colors[ index ]     = color.r;
      colors[ index + 1 ] = color.g;
      colors[ index + 2 ] = color.b;

      colors[ index + 3 ] = color.r;
      colors[ index + 4 ] = color.g;
      colors[ index + 5 ] = color.b;

      colors[ index + 6 ] = color.r;
      colors[ index + 7 ] = color.g;
      colors[ index + 8 ] = color.b;
    }

    // Create the geometry with the calculated parameters
    trianglesGeometry.addAttribute( 'position', new THREE.BufferAttribute( positions, 3 ) );
    trianglesGeometry.addAttribute( 'normal', new THREE.BufferAttribute( normals, 3 ) );
    trianglesGeometry.addAttribute( 'color', new THREE.BufferAttribute( colors, 3 ) );
    trianglesGeometry.computeBoundingSphere();

    // Material
    const material = new THREE.MeshPhongMaterial({
      color: 0xaaaaaa,
      specular: 0xffffff,
      shininess: 250,
      side: THREE.DoubleSide,
      vertexColors: THREE.VertexColors,
      wireframe: true
    });

    // Create the mesh
    this.trianglesMesh = new THREE.Mesh( trianglesGeometry, material );
  }

  private onWindowResize(): void {
    this.sceneWidth = window.innerWidth;

    this.camera.left = this.sceneWidth / - 2;
    this.camera.right = this.sceneWidth / 2;
    this.camera.top = this.sceneHeight / 2;
    this.camera.bottom = this.sceneHeight / - 2;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(
      this.sceneWidth,
      this.sceneHeight
    );

  }

  private init(): void {
    this.sceneAspectRatio = this.sceneWidth / this.sceneHeight;

    // Camera
    this.camera = new THREE.OrthographicCamera(
      this.sceneWidth / - 2,
      this.sceneWidth / 2,
      this.sceneHeight / 2,
      this.sceneHeight / - 2,
      0,
      900
    );

    this.camera.position.z = 600;

    // Scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color( 0x050505 );
    this.scene.fog = new THREE.Fog( 0x050505, 2000, 3500 );

    // Lights
    this.scene.add( new THREE.AmbientLight( 0x444444 ) );

    const light1 = new THREE.DirectionalLight( 0xffffff, 0.5 );
    light1.position.set( 1, 1, 1 );
    this.scene.add( light1 );

    const light2 = new THREE.DirectionalLight( 0xffffff, 1.5 );
    light2.position.set( 0, -1, 0 );
    this.scene.add( light2 );

    // Calculate the geometry
    this.generateTrianglesGeometry();
    this.scene.add( this.trianglesMesh );

    // Renderer
    this.renderer = new THREE.WebGLRenderer( { antialias: false } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( this.sceneWidth, this.sceneHeight );
    this.container.appendChild( this.renderer.domElement );

    // Events
    window.addEventListener( 'resize', () => this.onWindowResize(), false );

  }

  private render(): void {
    requestAnimationFrame( () => this.render() );

    let time = Date.now() * 0.001;
    this.trianglesMesh.rotation.x = time * 0.15;
    this.trianglesMesh.rotation.y = time * 0.25;

    this.renderer.render( this.scene, this.camera );
  }

  public animate(): void {
    this.render();
  }

  constructor (
    private sceneWidth: number = window.innerWidth,
    private sceneHeight: number = window.innerHeight,
    private container: HTMLElement = window.document.body
  ) {
    this.init();
  }
}
